<template>
  <div class="home">
    <head-nav></head-nav>
    <div class="home-box">
      <div class="home-banner">
        <swiper class="swiper">
          <swiper-slide
            v-for="(item, index) in banner"
            :key="index"
          >
            <img
              :src="item.image.publicUrl"
              alt=""
            >
          </swiper-slide>
        </swiper>
      </div>
<div class="about-list-wrap">
      <div class="about-list">
        <h3 class="about-list-title">
          <img
            :src="$t('about.title-1')"
            alt=""
            :style="this.$i18n.locale==='en'?'width:220px;height:auto;':''"
          >
        </h3>

        <div
          class="about-list-content"
          v-html="$t('about.about-introduce.list-1.content')"
        >
        </div>

        <!-- <div class="about-list-imgs">
          <div
            class="about-list-img"
            v-for="(item, index) in $t('about.about.list-1')"
            :key="index"
          >
            <img
              :src="item.img"
              alt=""
            >
          </div>
        </div> -->
      </div>
</div>

      <div class="home-introduce">
        <!-- <div class="introduce-lefts">
          <div
            class="introduce-left"
            v-html="info[0].content"
          >
          </div>
        </div> -->
        <div class="introduce-right">
          <div class="introduce-list">
            <span class="nums">01</span>
            <p class="title">{{$t('phone')}}</p>
            <div class="content">{{ info[0].phone }}</div>
          </div>
          <div class="introduce-list">
            <span class="nums">02</span>
            <p class="title">{{$t('fax')}}</p>
            <div class="content">{{ info[0].fax }}</div>
          </div>
        </div>
      </div>

      <div class="home-idea">
        <h3 class="home-idea-title">
          <img
            :src="$t('home.title-1')"
            alt=""
            :style="this.$i18n.locale==='en'?'width:260px;height:auto;':''"
          />
        </h3>
        <div class="lists">
          <div
            class="list"
            v-for="(item, index) in idea"
            :key="index"
          >
            <div class="list-left">
              <span class="list-nums">{{ (index + 1).toString().padStart(2, '0')  }}</span>
              <div class="list-img">
                <img
                  class="list-imgs"
                  :src="item.image.publicUrl"
                  alt=""
                />
              </div>
            </div>

            <div class="list-right">
              <h4 class="list-title">{{ item.title }}</h4>
              <p class="content">{{ item.subtitle[0] }}</p>
              <p class="contents">{{ item.subtitle[1] }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="home-news">
        <h3 class="news-title">
          <img
            :src="$t('home.title-2')"
            :style="this.$i18n.locale==='en'?'width:160px;height:auto;':''"
            alt=""
          />
        </h3>

        <div class="home-swiper-info">
          <swiper
            class="swiper"
            :options="swiperOption"
          >
            <swiper-slide
              class="swiper-list"
              v-for="(item, index) in news"
              :key="index"

            >
              <div class="swiper-info">
                <h4 class="swiper-title"   @click="toLink(item.id)">{{ item.title }}</h4>
                <span class="swiper-deta"> {{ item.createdAt | formatDate }} </span>
              </div>
              <p
                class="swiper-contetn"
                v-html="item.abstract"
              >
              </p>
              <div>
              </div>
            </swiper-slide>
            <div
              class="swiper-pagination"
              slot="pagination"
            ></div>
          </swiper>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

import HeadNav from './head-nav';
import Footers from './footers';

export default {
  name: 'home',
  components: {
    HeadNav,
    Footers,
    Swiper,
    SwiperSlide,
  },
  apollo: {
    banner: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              banner: allHomePageImages {
                id
                image {
                  publicUrl
                }
              }
            }
          `;
        }
        return gql`
          query {
            banner: allEnHomePageImages {
              id
              image {
                publicUrl
              }
            }
          }
        `;
      },
    },
    info: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              info: allHomePageInstroductions {
                content
                phone
                fax
              }
            }
          `;
        }
        return gql`
          query {
            info: allEnHomePageInstroductions {
              content
              phone
              fax
            }
          }
        `;
      },
    },
    idea: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              idea: allHomePageChessboards {
                title
                subtitle
                image {
                  publicUrl
                }
              }
            }
          `;
        }
        return gql`
          query {
            idea: allEnHomePageChessboards {
              title
              subtitle
              image {
                publicUrl
              }
            }
          }
        `;
      },
      update(value) {
        const newListright = value.idea.map((item) => ({
          ...item,
          subtitle: item.subtitle.split('\n'),
        }));
        return newListright;
      },
    },
    news: {
      query: gql`
        query {
          news: allPosts(orderBy: "createdAt_DESC", first: 5) {
            id
            title
            abstract
            createdAt
          }
        }
      `,
    },
  },

  data() {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
        },
      },
      info: [
        {
          content: '',
        },
      ],
      banner: [],
      idea: [],
      news: [],
    };
  },
  filters: {
    formatDate(value) {
      const date = new Date(value);
      return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}`;
    },
  },
  methods: {
    toLink(id) {
      this.$router.push({
        name: 'about-news-details',
        params: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  overflow: hidden;
  background: #f8f8f8;

  .home-box {
    .home-banner {
      width: 100%;

      img {
        width: 100%;
      }
    }

.about-list-wrap{
      padding: 40px 30px;
    background: #f8f8f8;
 .about-list {
      &-title {
        margin: 50px 0 30px 0;
        text-align: center;

        img {
          height: 70px;
        }
      }

      &-content {
        line-height: 40px;
        font-size: 28px;
        color: #646464;
      }

      &-imgs {
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        justify-content: space-between;
        border-radius: 10px;
        overflow: hidden;

        .about-list-img {
          width: 344.5px;

          &:nth-child(1) {
            width: 100%;
          }

          img {
            width: 100%;
            height: 300px;
          }
        }
      }
    }

}

    .home-introduce {
      margin: 40px 30px 0 30px;
      background: #fff;
      border: 1px solid #eee;
      border-radius: 10px;

      .introduce-lefts {
        padding: 50px 0 20px 0;

        .introduce-left {
          height: 200px;
          font-size: 28px;
          margin: 0 20px;
          color: #6e6e6e;
          line-height: 50px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
        }
      }

      .introduce-right {
        height: 310px;
        // border-top: 2px solid #eee;
        padding: 15px 0;
        margin: 0 20px;

        .introduce-list {
          width: 440px;
          height: 120px;
          border: 1px solid #eee;
          margin: 20px auto;
          position: relative;

          .nums {
            position: absolute;
            top: 40px;
            left: -40px;
            width: 120px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #ffbf00;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            font-size: 24px;
            transform: rotate(270deg);
            -webkit-transform: rotate(270deg);
            -moz-transform: rotate(270deg);
          }

          .title {
            padding: 20px 0 0 60px;
            font-size: 30px;
          }

          .content {
            padding: 10px 0 0 60px;
            font-size: 48px;
          }
        }
      }
    }

    .home-idea {
      margin: 0 30px;

      .home-idea-title {
        width: 100%;
        height: 140px;
        text-align: center;
        padding: 50px 0 30px 0;

        img {
          height: 64px;
        }
      }

      .lists {
        width: 100%;

        .list {
          width: 100%;
          height: 200px;
          border: 1px solid #eee;
          border-radius: 10px;
          display: flex;
          margin-bottom: 20px;
          overflow: hidden;

          &:nth-child(2n + 2) {
            background: #666666;

            .list-right {
              color: #fff;
            }
          }

          .list-left {
            width: 300px;
            position: relative;

            .list-nums {
              display: inline-block;
              width: 40px;
              height: 40px;
              line-height: 40px;
              background: #ffbf00;
              text-align: center;
              font-size: 18px;
              border-radius: 50%;
              position: absolute;
              top: 20px;
              left: 30px;
            }

            .list-img {
              position: absolute;
              top: 40px;
              left: 90px;
              width: 110px;
              height: 110px;

              .list-imgs {
                width: 100%;
                height: 100%;
              }
            }
          }

          .list-right {
            width: 460px;
            margin-right: 50px;

            .list-title {
              padding: 15px 0;
              font-size: 40px;
              font-weight: 600;
              border-bottom: 1px solid #eee;
            }

            p {
              font-size: 20px;
              line-height: 34px;
            }
          }
        }
      }
    }

    .home-news {
      .news-title {
        text-align: center;
        width: 100%;
        height: 140px;
        text-align: center;
        padding: 50px 0 30px 0;

        img {
          height: 64px;
        }
      }

      .home-swiper-info {
        margin: 0 30px 100px 30px;

        .swiper {
          border-radius: 10px;

          .swiper-list {
            height: 280px;
            background: #ffbf00;

            .swiper-info {
              display: flex;
              flex: 1;
              justify-self: start;

              .swiper-title {
                width: 500px;
                font-size: 30px;
                font-weight: 500;
                margin: 40px 20px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
              }

              .swiper-deta {
                display: inline-block;
                width: 250px;
                text-align: center;
                font-size: 24px;
                margin-top: 40px;
              }
            }

            .swiper-contetn {
              margin: 0 20px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              line-height: 34px;
            }
          }

          ::v-deep .swiper-pagination {
            .swiper-pagination-bullet {
              background: #fff;
              opacity: 1;
              width: 20px;
              height: 20px;
              margin: 0 10px;
            }

            .swiper-pagination-bullet-active {
              background: #000;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .home .home-box .about-list-imgs .about-list-img {
    width: 288px;
  }
}
</style>
